import React, { useState, useEffect, useRef } from "react";
import { Divider, Input, Select, Space } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics";
import {
    dropdownCategory,
    dropdownAction,
} from "../../utils/GoogleAnalytics/events/categories";
import "../../styles.css";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from "react-router-dom";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from "react-redux";
import useUserResponseFunctions from "../../helpers/SaveUserResponse";

const { Option } = Select;

const DropDownStepTextSelect = ( {
    question,
    selectedOptions,
    onNext,
    onBack,
    isPagination,
    isQuestionNavigation,
    isValidation,
    onClearAll,
    index
} ) => {
    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedOptions?.selectedOptions || [],
        textResponse: selectedOptions?.textResponse || '',
    } );

    const [isOptionSelected, setIsOptionSelected] = useState( true );
    const clientConfig = useSelector( ( state ) => state.clientConfiguration );

    useEffect( () => {
        setUserResponse( {
            selectedOptions: selectedOptions?.selectedOptions || [],
            textResponse: selectedOptions?.textResponse || '',
        } );
    }, [question] );

    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();

    const numericalDigits = question?.description.match( /\d+/g );
    const isDescriptionIncludesDigit = /\d/.test( question?.description );

    const handleSelect = ( selectedOptions ) => {
        if ( isDescriptionIncludesDigit && selectedOptions.length > numericalDigits ) {
            return;
        }

        const updatedUserResponse = {
            ...userResponse,
            selectedOptions
        };

        setUserResponse( updatedUserResponse );
        SaveUserResponseLocalStorage( { question: question, userResponse: updatedUserResponse, isPagination: isPagination, index: index } )
        setIsOptionSelected( selectedOptions.length > 0 );
        isValidation( true );
        TrackGoogleAnalyticsEvent( dropdownCategory, dropdownAction );
    };

    const { client_name } = useParams();

    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            onNext();
        }
    };

    const handleClearAll = () => {
        setUserResponse( { selectedOptions: [], textResponse: '' } );
        setIsOptionSelected( false );
        onClearAll();
    };

    const handleAnswerChange = ( event ) => {
        const newTextResponse = event.target.value;
        const updatedUserResponse = {
            ...userResponse,
            textResponse: newTextResponse
        };
        setUserResponse( updatedUserResponse );
        SaveUserResponseLocalStorage( { question: question, userResponse: updatedUserResponse, isPagination: isPagination, index: index } )
    };

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    return (
        <div
            className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col font-poppins">
                <QuestionAndDescription question={ question } />
                <input
                    placeholder="Write your own"
                    className="w-3/4 p-3 text-lg border border-gray-300 focus:outline-none focus:ring-0 rounded"
                    value={ userResponse?.textResponse }
                    onChange={ ( e ) => handleAnswerChange( e ) }
                    onKeyDown={ ( e ) => e.stopPropagation() }
                />
                <p className="w-3/4 text-center text-xl text-gray-800 my-4">OR</p>
                <Select
                    className="w-3/4 text-xl dropdown-text-field"
                    value={ userResponse?.selectedOptions }
                    onChange={ handleSelect }
                    showSearch={ true }
                    mode="tags"
                    placeholder="Select from dropdown"
                    filterOption={ ( input, option ) =>
                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                    }
                    onKeyDown={ handleKeyDown }
                    tabIndex={ 0 }
                >
                    { question?.options?.map( ( option ) => (
                        <Option key={ option.id } value={ option.id }>
                            { option.value }
                        </Option>
                    ) ) }
                </Select>
            </div>
            { isPagination ? (
                <></>
            ) : isQuestionNavigation ? (
                <>
                    <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                </>
            ) : (
                <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                    { question?.question_optional === "optional" && (
                        <button className="skip-button" onClick={ onNext }>
                            Skip
                        </button>
                    ) }
                    { perQuestionTimer ? (
                        <></>
                    ) : (
                        <button className="back-button" onClick={ onBack }>
                            Back
                        </button>
                    ) }
                    <button
                        className="next-button"
                        onClick={ isOptionSelected ? onNext : null }
                        style={ {
                            backgroundColor: isOptionSelected
                                ? `${ clientConfig.primary_color }`
                                : `${ clientConfig.primary_color }80`,
                            cursor: isOptionSelected ? "pointer" : "not-allowed",
                        } }
                    >
                        Next
                    </button>
                </div>
            ) }
        </div>
    );
};

export default DropDownStepTextSelect;
