import { useState } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';

const useRequestOTP = () => {
    const [phoneNumberData, setPhoneNumberData] = useState( null );
    const [isRequestingOTP, setIsRequestingOTP] = useState( false );
    const [errorOTP, setErrorOTP] = useState( null );

    const requestOTP = async ( phoneNumber, clientId ) => {
        setIsRequestingOTP( true );
        setErrorOTP( null );

        try {
            const response = await axios.post( 'https://auth.strideahead.in/otp', {
                phone_number: phoneNumber,
                client_id: clientId,
            } );

            setPhoneNumberData( response.data );
            setIsRequestingOTP( false );
        } catch ( err ) {
            setErrorOTP( err );
            setIsRequestingOTP( false );
            Sentry.captureException( err );
        }
    };

    return { phoneNumberData, isRequestingOTP, errorOTP, requestOTP };
};

export default useRequestOTP;
