import { useEffect } from 'react';
import useMultipleQuestionResponses from '../api/useMultipleQuestionResponses';
import useUpdateMultipleQuestionResponse from '../api/useUpdateMultipleQuestionResponse';
import useUpdateUserQuestionResponse from '../api/UpdateUserQuestionResponse';
import useCreateUserQuestionResponse from '../api/CreateUserQuestionResponse';
import { useDispatch } from 'react-redux';
import { updateAnswerIndex, resetAnswerStatus } from '../redux/student/studentSlice';
import { notification } from 'antd';

const useUserResponseFunctions = () => {

    const { updateUserQuestionResponse } = useUpdateUserQuestionResponse();
    const { createUserQuestionResponse, response: answerResponse } = useCreateUserQuestionResponse();
    const { updateQuestionResponse } = useMultipleQuestionResponses();
    const { updateMultipleQuestionResponse } = useUpdateMultipleQuestionResponse();

    const dispatch = useDispatch();

    const form_id = localStorage.getItem( 'form_id' );
    const userAssessmentResponseID = localStorage.getItem( 'userAssessmentResponseID' );

    useEffect( () => {
        if ( answerResponse ) {
            const answersResponseIdObject = JSON.parse( localStorage.getItem( `answersResponseId_${ form_id }_${ userAssessmentResponseID }` ) );
            answersResponseIdObject[`${ answerResponse.question_id }`] = answerResponse.id;
            localStorage.setItem( `answersResponseId_${ form_id }_${ userAssessmentResponseID }`, JSON.stringify( answersResponseIdObject ) );
        }
    }, [answerResponse] );

    const handleClearUserResponse = ( { question, currentDynamicStep } ) => {
        const textAnswersObject = JSON.parse( localStorage.getItem( `textAnswers_${ form_id }_${ userAssessmentResponseID }` ) );
        const answersResponseIdObject = JSON.parse( localStorage.getItem( `answersResponseId_${ form_id }_${ userAssessmentResponseID }` ) );
        const userResponseId = answersResponseIdObject[question?.id];
        textAnswersObject[`${ question.id }`] = "";

        if ( Number.isInteger( userResponseId ) ) {
            updateUserQuestionResponse( [], '', userResponseId )
        }
        dispatch( resetAnswerStatus( currentDynamicStep ) );
    }

    const handleUserResponseSubmit = ( { question, currentDynamicStep, setCurrentDynamicStep, userResponseString, userTextResponseStringify, isAddress } ) => {
        const textAnswersObject = JSON.parse( localStorage.getItem( `textAnswers_${ form_id }_${ userAssessmentResponseID }` ) );
        const answersResponseIdObject = JSON.parse( localStorage.getItem( `answersResponseId_${ form_id }_${ userAssessmentResponseID }` ) );

        const userResponse = textAnswersObject[question?.id];
        const userResponseId = answersResponseIdObject[question?.id];
        const textResponseInString = userResponseString && userResponse?.textResponse?.join( ", " );
        const textResponseStringify = userTextResponseStringify && JSON.stringify( userResponse?.textResponse );
        const textResponseAddress = isAddress && `${ userResponse?.textResponse?.houseNumber }, ${ userResponse?.textResponse?.city }, ${ userResponse?.textResponse?.state }, ${ userResponse?.textResponse?.country }, ${ userResponse?.textResponse?.pincode }`;

        const userTextResponse = userResponseString ? textResponseInString : userTextResponseStringify ? textResponseStringify : isAddress ? textResponseAddress : userResponse?.textResponse;

        if ( !userResponse?.selectedOptions && !userTextResponse ) {
            notification.error( {
                message: 'Please select an option before saving.'
            } )
            return;
        }

        if ( Number.isInteger( userResponseId ) ) {
            updateUserQuestionResponse( userResponse?.selectedOptions, userTextResponse, userResponseId )
        } else {
            createUserQuestionResponse( question?.id, userAssessmentResponseID, userResponse?.selectedOptions, userTextResponse );
        }
        dispatch( updateAnswerIndex( currentDynamicStep ) );
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };


    const SaveUserResponseLocalStorage = ( { question, userResponse, isPagination, index, userResponseString, userTextResponseStringify, isAddress } ) => {
        let textAnswersObject = JSON.parse( localStorage.getItem( `textAnswers_${ form_id }_${ userAssessmentResponseID }` ) );
        if ( !textAnswersObject ) {
            textAnswersObject = {};
        }
        const answersResponseIdObject = JSON.parse( localStorage.getItem( `answersResponseId_${ form_id }_${ userAssessmentResponseID }` ) );
        const answerResponseid = answersResponseIdObject ? answersResponseIdObject[question?.id] : '';
        textAnswersObject[`${ question?.id }`] = userResponse;
        localStorage.setItem( `textAnswers_${ form_id }_${ userAssessmentResponseID }`, JSON.stringify( textAnswersObject ) );

        const textResponseInString = userResponseString && userResponse?.textResponse?.join( ", " );
        const textResponseStringify = userTextResponseStringify && JSON.stringify( userResponse?.textResponse );
        const textResponseAddress = isAddress && `${ userResponse?.textResponse?.houseNumber }, ${ userResponse?.textResponse?.city }, ${ userResponse?.textResponse?.state }, ${ userResponse?.textResponse?.country }, ${ userResponse?.textResponse?.pincode }`;

        const userTextResponse = userResponseString ? textResponseInString : userTextResponseStringify ? textResponseStringify : isAddress ? textResponseAddress : userResponse?.textResponse;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = question.id;
                response.text_response = userTextResponse;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = userResponse?.selectedOptions?.map( optionId => ( { option_id: optionId } ) );
            } );
        }

        if ( isPagination && answerResponseid !== "" ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid;
                response.text_response = userTextResponse;
                response.options = userResponse?.selectedOptions?.map( optionId => ( { option_id: optionId } ) );
            } );
        }

    }

    return { handleUserResponseSubmit, SaveUserResponseLocalStorage, handleClearUserResponse };
}

export default useUserResponseFunctions;