import React, { useState, useEffect, useRef } from "react";
import { Spin, notification, Modal } from 'antd';
import { questionsConfig } from "./questionsConfig";
import Review from "./ReviewPage/Review";
import QuestionStep from "./SingleChoiceQuestions/SingleChoiceStep";
import MultiChoiceStep from "./MultipleChoiceQuestions/MultiChoiceStep";
import DropDownStepMultipleSelect from "./DropDownQuestions/DropDownStepMultipleSelect";
import DropDownStepSingleSelect from "./DropDownQuestions/DropDownStepSingleSelect";
import DropDownStepTextSelect from "./DropDownQuestions/DropDownStepTextSelect";
import TextInputStep from "./InputTypeText/TextInputStep";
import QuestionStepValidation from "./ValidationTypeQuestions/QuestionStepValidation";
import NumberInputStep from "./InputTypeNumber/NumberInputStep";
import PhoneNumberInputStep from "./InputTypeNumber/PhoneNumberInputStep";
import UploadFileInputType from "./UploadFileType/UploadFileTypeStep";
import EmailTypeStep from "./EmailTypeQuestion/EmailTypeStep";
import StateSelectionQuestion from "./StateSelectionQuestion/StateSelectionQuestion";
import { InfoCircleFilled } from '@ant-design/icons'
import "../css/bootstrap.min.css";
import "../css/bootstrap.css";
import "../css/style.css";
import { useEffectOnce, useMedia } from 'react-use';
import ProgressBar from "@ramonak/react-progress-bar";
import useFetchQuestion from "../api/FetchQuestions";
import useCreateUserAssessmentResponse from "../api/CreateUserAccessment";
import useCreateUserQuestionResponse from "../api/CreateUserQuestionResponse";
import { useParams, useNavigate, useLocation, unstable_usePrompt } from 'react-router-dom';
import MobileVerification from "./Verification/PhoneNumberVerification";
import EmailVerification from "./Verification/EmailVerification";
import LeftSideBar from "./LeftSideBar";
import AdvertiseMentModal from "../helpers/AdvertisementModal";
import { setIsConsentForm, resetState, setClientConfiguration, setFormConfiguration, updateAnswerIndex, resetAnswerStatus, setQuestionNumber, nextFlow } from '../redux/student/studentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { dynamicData, imageQuestion } from "../helpers/data";
import NameInputStep from "./InputTypeText/NameTypeQuestion";
import DobInputType from "./DobInputQuestion/DobInputQuestion";
import ThankYouPage from "./ThankYouPage/ThankYou";
import FlexibleInputStep from "./FlexibleInputStep/FlexibleInputStep";
import IndianStateSelectionQuestion from "./StateSelectionQuestion/IndianStateSelectionQuestion";
import IndianCitiesSelectionQuestion from "./StateSelectionQuestion/IndianCitiesSelectionQuestion";
import TestTimer from "./TestTimer/countDown";
import { current } from "@reduxjs/toolkit";
import TestTermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import { TERMS_AND_CONDITIONS, ASKIITIAN_TERMS_AND_CONDITIONS } from "../utils/constants/constants";
import ImageQuestionStep from "./ImageTypeQuestion/QuestionImage";
import updateUserResponse from "../api/useUpdateUserResponse";
import Lottie from 'lottie-react';
import animationData from '../assets/Animation/loading_animation.json';
import RangeTypeQuestion from "./RangeTypeQuestion/RangeTypeQuestion";
import OrderTypeQuestion from "./OrderTypeQuestion/OrderTypeQuestion";
import { useEvent } from 'react-use';
import PerQuestionTimer from "./TestTimer/PerQuestionTimer";
import useCreateSchoolCode from "../api/CreateSchoolCode";
import useCreateStudentCode from "../api/CreateStudentCode";
import useFetchClientConfig from '../api/FetchClientConfig';
import useFetchFormConfig from "../api/FetchFormConfig";
import useMultipleQuestionResponses from "../api/useMultipleQuestionResponses";
import TestSubmissionPage from "./TestSubmission/TestSubmission";
import './DataTables.css';
import useFetchFlowBlocks from "../api/FetchFlowBlocks";
import useFetchStudentDataEmail from "../api/FetchStudentDetailsEmail";
import useUpdateUserQuestionResponse from "../api/UpdateUserQuestionResponse";
import { Helmet } from 'react-helmet';
import InstructionsModalTest from "./InstructionPage/InstructionsModalTest";
import IndustrySelector from "./IndustrySelector/IndustrySelector";
import SkillSelector from "./SkillSelector/SkillSelector";
import useUpdateMultipleQuestionResponse from "../api/useUpdateMultipleQuestionResponse";
import useBlockNavigation from "../helpers/NavigationBlocker";
import SigninCouponCode from "./SignInPage/SigninCouponCode";
import AuthModal from "../helpers/AuthModal";
import { useBlocker } from "react-router-dom";
import AddressTypeQuestion from "./AddressTypeQuestion/AddressTypeQuestion";
import SubjectSelectionQuestion from "./SubjectsTypeQuestion/SubjectTypeQuestion";
import useUserResponseFunctions from "../helpers/SaveUserResponse";

const MultiStepForm = () => {
    const containerRef = useRef( null );

    const { handleUserResponseSubmit, handleClearUserResponse } = useUserResponseFunctions();

    const [pendingApiDataDropDown, setPendingApiDataDropDown] = useState( null );
    const [showProgressBar, setShowProgressBar] = useState( true )
    const [isClientActiveModal, setIsClientActiveModal] = useState( false );
    const [userIdError, setUserIdError] = useState( false );

    const [isTestAlreadyGiven, setIsTestAlreadyGiven] = useState( false );
    const [refreshTest, setRefreshTest] = useState( false );

    const [isUserRegistered, setIsUserRegistered] = useState( true );
    const [backUpdateQuestionsResponse, setBackUpdateQuestionsResponse] = useState( false );

    const [instructionModalTest, setInstructionModalTest] = useState( false );

    const [answerResponseid, setAnswerResponseid] = useState( [] );
    const [questionId, setQuestionId] = useState( [] );

    const dispatch = useDispatch();
    const questionNumber = useSelector( state => state.currentQuestion.questionNumber );
    const isConsentForm = useSelector( state => state.consentForm.isConsentForm )
    const formConfig = useSelector( state => state.formConfiguration );
    const clientConfig = useSelector( state => state.clientConfiguration );

    const isRequiredQuestionsAttempted = useSelector( state => state.requiredQuestionsAttempted.isRequiredQuestionsAttempted )

    const isClientActive = clientConfig?.is_active;

    const [isAllAnswersValid, setIsAllAnswersValid] = useState( false );

    const [isModalOpen, setIsModalOpen] = useState( false );

    const [dynamicQuestions, setDynamicQuestions] = useState( [] );

    const [textAnswers, setTextAnswers] = useState( [] );

    const { client_name, user_type, test_type, testId } = useParams();

    const clientConfiguration = sessionStorage.getItem( 'clientConfiguration' );
    const formConfiguration = sessionStorage.getItem( 'formConfiguration' );

    const { response: updateUserResponseData, isLoading: updateUserResponseLoading, error: updateUserResponseError, updateResponse } = updateUserResponse();

    const { response: studentData, isLoading: studentDataLoading, error: studentDataError, FetchStudentDataEmail } = useFetchStudentDataEmail();

    const { response: flowBlocks, isLoading: flowLoading, FetchFlowBlocks } = useFetchFlowBlocks();

    const [currentDynamicStep, setCurrentDynamicStep] = useState( 0 );

    useEffect( () => {
        if ( testId ) {
            FetchFlowBlocks( testId );
        }
    }, [testId] )

    const { response: clientConfigData, isLoading, fetchClientConfig } = useFetchClientConfig();
    const { response: formConfigData, isLoading: formConfigLoading, fetchFormConfig } = useFetchFormConfig();

    useEffect( () => {
        if ( client_name && !clientConfiguration ) {
            fetchClientConfig( client_name );
        }
    }, [client_name] )

    useEffect( () => {
        localStorage.setItem( 'testId', testId );
    }, [testId] )

    useEffect( () => {
        if ( client_name && !formConfiguration && isClientActive ) {
            fetchFormConfig( client_name, test_type, testId );
        }
    }, [client_name, isClientActive] )

    useEffect( () => {
        if ( clientConfig && !isClientActive && isClientActive !== undefined ) {
            setIsClientActiveModal( true );
        }
    }, [isClientActive, clientConfig] )

    useEffect( () => {
        if ( clientConfigData && clientConfigData?.data ) {
            dispatch( setClientConfiguration( clientConfigData.data ) );
            sessionStorage.setItem( 'clientConfiguration', JSON.stringify( clientConfigData.data ) );

            const timeout = setTimeout( () => {
                sessionStorage.removeItem( 'clientConfiguration' );
                fetchClientConfig( client_name );
            }, 10 * 60 * 1000 );
            return () => clearTimeout( timeout );
        }
    }, [clientConfigData, client_name] );

    useEffect( () => {
        if ( formConfigData && formConfigData?.data?.form_config ) {
            dispatch( setFormConfiguration( formConfigData?.data ) );
            sessionStorage.setItem( 'formConfiguration', JSON.stringify( formConfigData?.data ) );

            const timeout = setTimeout( () => {
                sessionStorage.removeItem( 'formConfiguration' );
                fetchFormConfig( client_name, test_type, testId );
            }, 60 * 60 * 1000 );
            return () => clearTimeout( timeout );
        }
    }, [formConfigData, formConfigLoading] )

    const data = formConfigData?.data ? formConfigData?.data?.form_data : formConfig?.form_data;
    const form_config_data =
        formConfigData?.data ? formConfigData?.data?.form_config : formConfig?.form_config;


    const form_type = data?.form_type;
    const template_id = data?.pdf_template_name;
    const form_id = form_config_data?.form_id;
    const batchSize = form_config_data?.questions_per_page || '';
    const form_name = data?.name;
    const instructions = form_config_data?.instructions;
    const isQuestionNavigation = form_config_data?.is_question_navigation

    const [addedFollowUps, setAddedFollowUps] = useState( [] );
    const [showAdvertisement, setShowAdvertisement] = useState( false );
    const { response: multipleAnswerResponse, updateQuestionResponse, multipleQuestionResponses } = useMultipleQuestionResponses( batchSize );
    const { updateMultipleQuestionResponse, updateMultipleQuestionResponseCall } = useUpdateMultipleQuestionResponse( batchSize );

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState( false );

    useEffect( () => {
        localStorage.setItem( 'form_id', form_id );
        localStorage.setItem( 'clientId', form_config_data?.client_id );
    }, [form_id, form_config_data] )

    useEffect( () => {
        // Example logic to determine if there are unsaved changes
        // Replace this with your actual logic
        const checkForUnsavedChanges = () => {
            // Set hasUnsavedChanges to true if there are unsaved changes
            setHasUnsavedChanges( true );
        };

        // Add event listener or other logic to check for unsaved changes
        // For example, you can listen to form input changes or other state changes
        checkForUnsavedChanges();
    }, [] );

    useBlockNavigation( hasUnsavedChanges );

    const resetAdvertisement = () => {
        setShowAdvertisement( false );
    };

    const handleProceedToQuiz = () => {
        dispatch( setIsConsentForm( false ) );
    };

    const navigate = useNavigate();
    const location = useLocation();

    const { test_series_id } = location.state || {};
    const currentQueryParams = location.search;

    const params = new URLSearchParams( location.search );
    const utmSource = params.get( 'utm_source' );
    const utmMedium = params.get( 'utm_medium' );
    const utmCampaign = params.get( 'utm_campaign' );
    const userEmail = params.get( 'user_email' );
    const userName = params.get( 'user_name' );
    const userId = params.get( 'user_id' );
    const studentPhone = params.get( 'phone' );
    const retakeTest = params.get( 'retake' );

    useEffect( () => {
        if ( userEmail ) {
            FetchStudentDataEmail( userEmail, client_name )
        } else if ( !userEmail && isQuestionNavigation ) {
            setIsUserRegistered( false );
        }
    }, [userEmail] )

    const [workFlowList, setWorkFlowList] = useState( [] );
    const [currentComponent, setCurrentComponent] = useState( 0 );
    const [currentFlowName, setCurrentFlowName] = useState( "" )

    useEffect( () => {
        let workflowList = [];
        if ( flowBlocks ) {
            workflowList = flowBlocks.map( item => item.block_type );
        }
        setWorkFlowList( workflowList );
    }, [flowBlocks] );

    useEffect( () => {
        if ( workFlowList?.length > 0 ) {
            setCurrentFlowName( workFlowList[currentComponent] );
        }
    }, [currentComponent, workFlowList] );

    useEffect( () => {
        if ( clientConfigData ) {
            document.title = clientConfigData?.data?.tag_line;
            document.querySelector( 'meta[name="description"]' ).setAttribute( "content", clientConfigData?.data?.description );
        }
    }, [clientConfigData] );

    const terms_and_conditions = dynamicData[form_config_data?.client_id]?.terms_and_conditions || "";

    //const isCouponCodeVerification = form_config_data?.coupon_code_verification || '';
    const viewReport = form_config_data?.view_report || '';

    //const is_mobile_verification = form_config_data?.is_mobile_verification || '';
    //const is_email_verification = form_config_data?.is_email_verification || '';
    //const is_Loading_Screen = form_config_data?.is_loading_screen || '';
    const isPagination = form_config_data?.is_pagination || '';

    const testTimeInSeconds = form_config_data?.overall_timer || '';

    const isLeftSidebarVisible = form_config_data?.is_left_sidebar_visible || ''

    useEffect( () => {
        let productCode = "";
        if ( user_type === 'college' ) {
            productCode = "COLLEGE_SURVEY";
        } else if ( user_type === 'workingprofessional' ) {
            productCode = "PROFESSIONAL_SURVEY";
        }
        localStorage.setItem( 'product_code', productCode );
    }, [user_type] )

    //const { data, isLoading, isError } = useFetchQuestion( form_id );

    const { createSchoolCode, response: createSchoolResponse, error: schoolError, isLoading: creatingSchool } = useCreateSchoolCode();
    const { createStudentCode, response: createStudentResponse, error: studentError, isLoading: creatingStudent } = useCreateStudentCode();

    const [userAssessmentResponseID, setUserAssessmentResponseID] = useState(
        localStorage.getItem( 'userAssessmentResponseID' )
    );

    useEffect( () => {
        if ( data?.questions ) {
            setDynamicQuestions( [...data.questions] );
        }
    }, [data] );

    useEvent( 'keydown', ( e ) => {
        if ( e.key === 'Tab' ) {
            e.preventDefault();
        }
    } );


    const [validations, setValidations] = useState( [] );

    useEffect( () => {
        if ( dynamicQuestions?.length && isPagination ) {
            const initialBatchSize = Math.min( batchSize, dynamicQuestions?.length );
            setValidations( Array.from( { length: initialBatchSize }, () => ( {} ) ) )
        }
    }, [dynamicQuestions?.length] );

    //const userId = localStorage.getItem( 'user_id' ) || localStorage.getItem( 'student_roll_number' ) || 0;

    const is_completed = localStorage.getItem( 'is_completed' ) || 1;

    const { response, error, CreateUserAssessmentResponse } = useCreateUserAssessmentResponse()

    const createAccessmentIdTest = () => {
        if ( data && studentData && !response ) {
            dispatch( setQuestionNumber( 0 ) );
            if ( !studentData?.student_code ) {
                dispatch( resetState() );
            }
            if ( userId ) {
                CreateUserAssessmentResponse( userId, form_id, is_completed, testId, retakeTest );
            } else if ( !userId && studentData ) {
                const newUserid = studentData?.student_code || studentData?.id;
                CreateUserAssessmentResponse( newUserid, form_id, is_completed, testId, retakeTest );
            } else if ( !userId && !studentData?.student_code ) {
                CreateUserAssessmentResponse( 0, form_id, is_completed, testId, retakeTest );
            }
        }
    }

    useEffect( () => {
        createAccessmentIdTest();
    }, [data, studentData] )

    const hasRun = useRef( false );

    const CreateAssessmentIdSurvey = () => {
        if ( !hasRun.current && data && !isQuestionNavigation && !response ) {
            hasRun.current = true; // Prevent further executions
            dispatch( setQuestionNumber( 0 ) );

            if ( userId ) {
                CreateUserAssessmentResponse( userId, form_id, is_completed, testId, retakeTest );
            } else if ( !userId && studentData ) {
                const newUserid = studentData?.student_code || studentData?.id;
                CreateUserAssessmentResponse( newUserid, form_id, is_completed, testId, retakeTest );
            } else if ( !userId && !studentData?.student_code ) {
                CreateUserAssessmentResponse( 0, form_id, is_completed, testId, retakeTest );
            }
        }
    }

    useEffectOnce( () => {
        CreateAssessmentIdSurvey();
    }, [] )

    const [initialSize, setInitialSize] = useState( {
        width: typeof window !== 'undefined' ? window.outerWidth : 0,
        height: typeof window !== 'undefined' ? window.outerHeight : 0,
    } );

    useEffect( () => {
        if ( !isQuestionNavigation ) return;

        let resizeTimeout;

        const handleResize = () => {
            const currentWidth = window.outerWidth;
            const currentHeight = window.outerHeight;
            clearTimeout( resizeTimeout );

            console.log( `Width: ${ currentWidth }, Height: ${ currentHeight }` );

            resizeTimeout = setTimeout( () => {
                if ( currentWidth !== initialSize.width || currentHeight !== initialSize.height ) {
                    notification.warning( {
                        message: "You've altered the window size.",
                        description: "Further resizing will auto-submit your assessment.",
                    } );

                    setInitialSize( {
                        width: currentWidth,
                        height: currentHeight,
                    } );
                }
            }, 500 );
        };

        window.addEventListener( 'resize', handleResize );

        return () => {
            window.removeEventListener( 'resize', handleResize );
            clearTimeout( resizeTimeout );
        };
    }, [isQuestionNavigation, initialSize] );

    useEffect( () => {
        if ( response )
            if ( userAssessmentResponseID === null && form_type === "survey" ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
            } else if ( userAssessmentResponseID === null && form_type === "test" ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
            } else if ( userAssessmentResponseID !== null && form_type === "survey" ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
            } else if ( userAssessmentResponseID !== null && form_type === "test" ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
            }
        setUserAssessmentResponseID( localStorage.getItem( 'userAssessmentResponseID' ) )
    }, [response] )

    useEffect( () => {
        if ( response && response?.is_completed === 3 ) {
            setIsTestAlreadyGiven( true );
        }
    }, [response] )

    useEffect( () => {
        if ( userEmail && userName && response && ( !studentData?.student_code && !studentData?.id ) ) {
            createStudentCode( userName, "", userEmail, client_name, response.id, "", form_config_data?.client_id, studentPhone )
        }
    }, [response, studentData] );

    useEffect( () => {
        if ( studentData && studentData?.student_code ) {
            localStorage.setItem( 'student_roll_number', studentData?.student_code );
        }
    }, [studentData] )


    useEffect( () => {
        if ( !isConsentForm ) {
            updateResponse( { id: userAssessmentResponseID, is_completed: 2, clientName: client_name } )
        }
    }, [isConsentForm, userAssessmentResponseID] )

    useEffect( () => {
        if ( response && response?.is_completed === 2 && testTimeInSeconds === 0 ) {
            setRefreshTest( false );
        } else if ( response && response?.is_completed === 2 && testTimeInSeconds > 0 ) {
            setRefreshTest( true );
        }
    }, [response] )


    useEffect( () => {
        if ( dynamicQuestions ) {
            const ids = dynamicQuestions.map( question => question.id );
            setQuestionId( ids );
        }
    }, [dynamicQuestions] )

    useEffect( () => {
        if ( !userAssessmentResponseID ) {
            // No ID in localStorage, make API call
            if ( response?.id && localStorage.getItem( 'userAssessmentResponseID' ) === null ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
                setUserAssessmentResponseID( response.id );
            }
        }
        localStorage.setItem( 'form_id', form_id );
        localStorage.setItem( 'clientId', form_config_data?.client_id );
    }, [response, form_id, userAssessmentResponseID] );

    useEffect( () => {
        setCurrentDynamicStep( questionNumber );
    }, [questionNumber] );

    useEffect( () => {
        // Update the stored currentDynamicStep whenever it changes
        dispatch( setQuestionNumber( currentDynamicStep ) );
    }, [currentDynamicStep] );


    useEffect( () => {
        // Check if textAnswers is non-empty before updating local storage
        if ( questionId.length > 0 && userAssessmentResponseID !== null ) {
            localStorage.setItem( `questionId_${ form_id }_${ userAssessmentResponseID }`, JSON.stringify( questionId ) );
        }
    }, [textAnswers, form_id, userAssessmentResponseID] );


    const { createUserQuestionResponse, response: answerResponse } = useCreateUserQuestionResponse();

    const { updateUserQuestionResponse } = useUpdateUserQuestionResponse();

    useEffect( () => {
        if ( multipleAnswerResponse ) {
            const ids = multipleAnswerResponse.map( response => response.id );
            setAnswerResponseid( ids );
        }
    }, [multipleAnswerResponse] )

    // const handleSingleChoiceSelect = ( option, currentDynamicStep, index ) => {

    //     let newTextAnswers = [...textAnswers];
    //     newTextAnswers[currentDynamicStep] = option;
    //     setTextAnswers( newTextAnswers );

    //     const currentQuestionID = dynamicQuestions[currentDynamicStep].id;
    //     const selectedOptionId = option?.selectedOptions[0];


    //     if ( isPagination ) {
    //         updateQuestionResponse( index, response => {
    //             response.question_id = currentQuestionID;
    //             response.text_response = "";
    //             response.time_taken = 0;
    //             response.user_assessment_response_id = userAssessmentResponseID;
    //             response.options = [selectedOptionId];
    //         } );
    //     }

    //     if ( isPagination && answerResponseid[index] ) {
    //         updateMultipleQuestionResponse( index, response => {
    //             response.id = answerResponseid[index];
    //             response.text_response = "";
    //             response.options = [selectedOptionId];;
    //         } );
    //     }

    //     const updatedDynamicQuestions = [...dynamicQuestions];
    //     const prevFollowUpIndex = addedFollowUps[currentDynamicStep];

    //     // If there was a previous follow-up question, remove it
    //     if ( prevFollowUpIndex !== undefined ) {
    //         updatedDynamicQuestions.splice( prevFollowUpIndex, 1 );
    //         setAddedFollowUps( { ...addedFollowUps, [currentDynamicStep]: undefined } );
    //     }

    //     // If the new option has a follow-up question, add it
    //     if ( option?.follow_up_question ) {
    //         updatedDynamicQuestions.splice( currentDynamicStep + 1, 0, option.follow_up_question );
    //         setAddedFollowUps( { ...addedFollowUps, [currentDynamicStep]: currentDynamicStep + 1 } );

    //     }
    //     setDynamicQuestions( updatedDynamicQuestions );
    // };


    const handleCourseSelect = ( answer, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = answer;
                response.options = [];
            } );
        }


        setPendingApiDataDropDown( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: answer
        } );
    };

    const handleBack = () => {
        if ( currentDynamicStep === 0 ) return null;
        setCurrentDynamicStep( currentDynamicStep - 1 );
    };


    const handleNext = () => {
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const handleUploadFileChange = ( answer, currentDynamicStep, index ) => {

        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer?.uploadedLink;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = answer?.uploadedLink;
                response.options = [];
            } );
        }
    };

    useEffect( () => {
        // Ensure form_id and userAssessmentResponseID are defined before proceeding
        if ( !form_id || !userAssessmentResponseID ) {
            console.warn( "form_id or userAssessmentResponseID is undefined or null" );
            return; // Exit if values are not defined
        }

        // Initialize empty objects to store question and response data
        const transformedObject = Object.fromEntries(
            dynamicQuestions.map( question => [`${ question.id }`, ""] )
        );
        const transformedObjectAnswers = Object.fromEntries(
            dynamicQuestions.map( question => [`${ question.id }`, ""] )
        );

        // Set initial empty values in local storage with valid keys
        localStorage.setItem( `textAnswers_${ form_id }_${ userAssessmentResponseID }`, JSON.stringify( transformedObject ) );
        localStorage.setItem( `answersResponseId_${ form_id }_${ userAssessmentResponseID }`, JSON.stringify( transformedObjectAnswers ) );

        // Retrieve question ID array from local storage or initialize as an empty array if not found
        const questionIdArray = JSON.parse( localStorage.getItem( `questionId_${ form_id }_${ userAssessmentResponseID }` ) ) || [];

        // Check if response and response.question_responses exist and contain data
        if ( response && response.question_responses?.length > 0 ) {
            // Initialize objects to store question response data for local storage
            const questionResponses = {
                textAnswers: { ...transformedObject },
                answersResponseId: { ...transformedObjectAnswers },
            };

            // Loop through each item in response.question_responses array
            response.question_responses.forEach( item => {
                // Create an object with selected options and text response for each question
                const questionData = {
                    selectedOptions: item.options?.map( option => option.option_id ) || [], // Array of selected option IDs or empty array
                    textResponse: item.text_response || "", // Store text response or default to an empty string
                };

                // Update the textAnswers object with question ID as key and question data as value
                questionResponses.textAnswers[item.question_id] = questionData;

                // Update the answersResponseId object with question ID as key and response ID as value
                questionResponses.answersResponseId[item.question_id] = item.id;
            } );

            // Update local storage with the transformed question responses using valid keys
            localStorage.setItem( `textAnswers_${ form_id }_${ userAssessmentResponseID }`, JSON.stringify( questionResponses.textAnswers ) );
            localStorage.setItem( `answersResponseId_${ form_id }_${ userAssessmentResponseID }`, JSON.stringify( questionResponses.answersResponseId ) );

            // Dispatch updateAnswerIndex for each question ID found in question_responses
            response.question_responses.forEach( item => {
                // Find index of question_id in questionIdArray
                const questionIndex = questionIdArray.indexOf( item.question_id );

                if ( questionIndex !== -1 ) {
                    // Check if `options` is not an empty array or `text_response` is not an empty string
                    const optionsNotEmpty = item.options && item.options.length > 0;
                    const textResponseNotEmpty = item.text_response && item.text_response.trim() !== "";

                    if ( optionsNotEmpty || textResponseNotEmpty ) {
                        // Dispatch updateAnswerIndex with the index if one of the conditions is true
                        dispatch( updateAnswerIndex( questionIndex ) );
                    }
                }
            } );

        }
    }, [dynamicQuestions, form_id, userAssessmentResponseID, data, form_id] );

    const handleNextUpload = () => {
        const currentQuestionID = questionId[currentDynamicStep];
        const textResponse = textAnswers[currentDynamicStep];
        const uploadedFileLink = textResponse?.uploadedLink;

        if ( uploadedFileLink ) {
            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], uploadedFileLink, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], uploadedFileLink );
            }
            dispatch( updateAnswerIndex( currentDynamicStep ) );
        } else {
            // Dispatch resetAnswerStatus if selectedOptionId is empty
            dispatch( resetAnswerStatus( currentDynamicStep ) );
        }

        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const handleDropDownTextComponent = ( userResponse, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = userResponse;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;
        const selectedOptionId = userResponse;


        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = "";
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [selectedOptionId];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = "";
                response.options = [selectedOptionId];;
            } );
        }
    };

    const handleDropDownTextComponentNext = () => {
        const currentQuestionID = questionId[currentDynamicStep];
        const selectedOptionId = textAnswers[currentDynamicStep];

        if ( selectedOptionId?.selectedOptions?.length > 0 || selectedOptionId?.textResponse !== '' ) {
            // Dispatch updateAnswerIndex if selectedOptionId is not empty
            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( selectedOptionId?.selectedOptions, selectedOptionId?.textResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedOptionId?.selectedOptions, selectedOptionId?.textResponse );
            }
            dispatch( updateAnswerIndex( currentDynamicStep ) );
        } else {
            // Dispatch resetAnswerStatus if selectedOptionId is empty
            dispatch( resetAnswerStatus( currentDynamicStep ) );
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    }

    const completedProgress = Math.round( ( currentDynamicStep / dynamicQuestions.length ) * 100 );

    const renderQuestionComponent = ( currentDynamicStep, index ) => {
        const textAnswersObject = JSON.parse( localStorage.getItem( `textAnswers_${ form_id }_${ userAssessmentResponseID }` ) );
        const questionToRender = dynamicQuestions[currentDynamicStep];
        const AttemptedUserResponse = textAnswersObject && textAnswersObject[questionToRender?.id];

        switch ( questionToRender?.input_type ) {
            case "single_choice_question":
                return (
                    <QuestionStep
                        question={ questionToRender }
                        selectedOptions={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "multiple_choice_question":
                return (
                    <MultiChoiceStep
                        question={ questionToRender }
                        selectedOptions={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "drop_down_multiselect_question":
                return (
                    <DropDownStepMultipleSelect
                        question={ questionToRender }
                        selectedOptions={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "drop_down_singleselect_question":
                return (
                    <DropDownStepSingleSelect
                        question={ questionToRender }
                        selectedOptions={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                        courseName={ ( answer ) => handleCourseSelect( answer, currentDynamicStep, index ) }
                        selectedCourseName={ AttemptedUserResponse }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "drop_down_text_field":
                return (
                    <DropDownStepTextSelect
                        question={ questionToRender }
                        selectedOptions={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "text_area_question":
                return (
                    <TextInputStep
                        question={ questionToRender }
                        selectedAnswer={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "address_input_type":
                return (
                    <AddressTypeQuestion
                        question={ questionToRender }
                        selectedAnswer={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext, isAddress: true } ) }
                        onBack={ handleBack }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "number_input_question":
                return (
                    <NumberInputStep
                        question={ questionToRender }
                        selectedAnswer={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "upload_type_question":
                return (
                    <UploadFileInputType
                        question={ questionToRender }
                        selected={ AttemptedUserResponse }
                        onSelect={ ( answer ) => handleUploadFileChange( answer, currentDynamicStep, index ) }
                        onNext={ handleNextUpload }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                    />
                );
            case "email_type_question":
                return (
                    <EmailTypeStep
                        question={ questionToRender }
                        selectedAnswer={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                )
            case "phone_number_input_question":
                return (
                    <PhoneNumberInputStep
                        question={ questionToRender }
                        selectedAnswer={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                )
            case "name_input_question":
                return (
                    <NameInputStep
                        question={ questionToRender }
                        selectedAnswer={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "date_input_type":
                return (
                    <DobInputType
                        question={ questionToRender }
                        selectedAnswer={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "number_and_text_input_type":
                return (
                    <FlexibleInputStep
                        question={ questionToRender }
                        selectedAnswer={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "indian_state_selection_type":
                return (
                    <IndianStateSelectionQuestion
                        question={ questionToRender }
                        selectedAnswer={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "subject_selection_question":
                return (
                    <SubjectSelectionQuestion
                        question={ questionToRender }
                        selectedAnswer={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext, userResponseString: true } ) }
                        onBack={ handleBack }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "indian_city_selection_type":
                return (
                    <IndianCitiesSelectionQuestion
                        question={ questionToRender }
                        selectedAnswer={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "nudge":
                return (
                    <AdvertiseMentModal
                        onNext={ () => {
                            setShowProgressBar( true )
                            setCurrentDynamicStep( currentDynamicStep + 1 )
                        } }
                        resetAdvertisement={ resetAdvertisement }
                        nudge={ dynamicQuestions[currentDynamicStep]?.nudge }
                        onLoad={ () => setShowProgressBar( false ) }
                        onBack={ () => {
                            setShowProgressBar( true );
                            setCurrentDynamicStep( currentDynamicStep - 1 )
                        } }
                        isPagination={ isPagination }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "ranking_input_type":
                return (
                    <OrderTypeQuestion
                        question={ questionToRender }
                        selectedOptions={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "range_type_question":
                return (
                    <RangeTypeQuestion
                        question={ questionToRender }
                        selectedAnswer={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext } ) }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "industry_selector":
                return (
                    <IndustrySelector
                        question={ questionToRender }
                        selectedOptions={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext, userResponseString: true } ) }
                        onBack={ handleBack }
                        onClearAll={ () => handleClearUserResponse( { question: questionToRender, currentDynamicStep: currentDynamicStep } ) }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            case "skill_selector":
                return (
                    <SkillSelector
                        question={ questionToRender }
                        selectedOptions={ AttemptedUserResponse }
                        onNext={ () => handleUserResponseSubmit( { question: questionToRender, currentDynamicStep: currentDynamicStep, setCurrentDynamicStep: handleNext, userTextResponseStringify: true } ) }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                        index={ index }
                    />
                );
            default:
                return (
                    <TestSubmissionPage />
                );
        }
    };

    const renderQuestionBatch = ( startIndex ) => {
        return dynamicQuestions
            .slice( startIndex, startIndex + batchSize )
            .map( ( question, index ) => {
                return renderQuestionComponent( startIndex + index, index );
            } );
    };

    const handleResetValidations = ( size = batchSize ) => {
        setValidations( Array( size ).fill( {} ) ); // Initialize validations based on the actual size passed
    };


    useEffect( () => {
        const allTrue = validations.length > 0 && validations.every( value => value === true );
        setIsAllAnswersValid( allTrue );
    }, [validations] );


    const handleNextBatch = ( startIndex ) => {
        const questionCount = dynamicQuestions.length - startIndex;
        const currentBatchSize = Math.min( questionCount, batchSize );

        if ( isAllAnswersValid ) {
            if ( backUpdateQuestionsResponse ) {
                updateMultipleQuestionResponseCall();
            } else {
                multipleQuestionResponses();
            }
            setCurrentDynamicStep( startIndex );

            containerRef.current.scrollTo( 0, 0 );
            window.scrollTo( {
                top: 0,
                behavior: 'smooth',
            } );

            if ( startIndex >= dynamicQuestions.length ) {
                return;
            } else {
                handleResetValidations( currentBatchSize ); // Only reset with a positive size
                setBackUpdateQuestionsResponse( false );
            }
        } else {
            notification.error( {
                message: 'Please attempt all the questions',
            } );
        }
    };

    const handlePreviousBatch = ( startIndex ) => {
        if ( currentDynamicStep === 0 ) return null;
        setBackUpdateQuestionsResponse( true )
        setCurrentDynamicStep( startIndex );
        containerRef.current.scrollTo( 0, 0 );
    };

    const renderQuestions = ( startIndex ) => {
        return (
            <>
                { isPagination ? (
                    <>
                        { renderQuestionBatch( startIndex ) }

                        <div className="flex flex-row gap-2 justify-end pb-8">
                            <button
                                className="next-button"
                                onClick={ () => handleNextBatch( startIndex + batchSize ) }
                                style={ {
                                    backgroundColor: isAllAnswersValid ? clientConfig.primary_color : `${ clientConfig.primary_color }80`,
                                    cursor: isAllAnswersValid ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>

                            <button
                                className="back-button"
                                onClick={ () => handlePreviousBatch( startIndex - batchSize ) }
                            >
                                Back
                            </button>
                        </div>
                    </>
                ) : (
                    renderQuestionComponent( startIndex )
                ) }
            </>
        );
    };

    //const handleFinishAttempt = () => {
    //  if ( isRequiredQuestionsAttempted ) {
    //    updateResponse( { id: userAssessmentResponseID, is_completed: 3 } )
    //    dispatch( setIsLoadingScreen( true ) );
    //    localStorage.setItem( 'isLoadingScreen', true )
    //  } else {
    //    notification.error( {
    //      message: 'Unable to submit!',
    //      description: 'Please attempt all the required questions to submit the test.',
    //    } );
    //  }
    //}

    //const handleFinishAttempt = () => {
    //  updateResponse( { id: userAssessmentResponseID, is_completed: 3 } )
    //  dispatch( setIsLoadingScreen( true ) );
    //  localStorage.setItem( 'isLoadingScreen', true )
    //  setIsModalOpen( false );
    //}

    useEffect( () => {
        if ( currentDynamicStep > 0 )
            if ( data?.questions && currentDynamicStep + 1 > dynamicQuestions.length ) {
                setIsModalOpen( true );
            } else {
                setIsModalOpen( false );
            }
    }, [currentDynamicStep] )

    const handleOk = () => {
        if ( isQuestionNavigation ) {
            if ( isRequiredQuestionsAttempted ) {
                updateResponse( { id: userAssessmentResponseID, is_completed: 3, clientName: client_name } )
                // setCurrentComponent( currentComponent + 1 );
                dispatch( nextFlow() );
                dispatch( resetState() );
                if ( test_series_id ) {
                    navigate( `/${ client_name }/thank_you/${ currentQueryParams }` )
                } else {
                    navigate( `/${ client_name }/${ test_type }/flow/` )
                }
                setIsModalOpen( false );
            } else {
                notification.error( {
                    message: 'Unable to submit!',
                    description: 'Please attempt all the questions to submit the test.',
                } );
            }
        } else {
            updateResponse( { id: userAssessmentResponseID, is_completed: 3, clientName: client_name } )
            // setCurrentComponent( currentComponent + 1 );
            dispatch( nextFlow() );
            dispatch( resetState() );
            if ( test_series_id ) {
                navigate( `/${ client_name }/thank_you/${ currentQueryParams }` )
            } else {
                navigate( `/${ client_name }/${ test_type }/flow/` )
            }
            setIsModalOpen( false );
        }
    };

    useEffect( () => {
        if ( updateUserResponseData && updateUserResponseData?.is_completed === 3 ) {
            dispatch( nextFlow() );
            dispatch( resetState() );
            navigate( `/${ client_name }/${ test_type }/flow/` )
        }
    }, [updateUserResponseData] );

    // useEffect( () => {
    //     if ( createSchoolResponse && !schoolError ) {
    //         localStorage.clear();
    //     } else if ( createStudentResponse && !studentError ) {
    //         const studentRollNumber = createStudentResponse?.student_code;
    //         localStorage.setItem( 'student_roll_number', studentRollNumber );
    //         if ( viewReport ) {
    //             Object.keys( localStorage ).forEach( ( key ) => {
    //                 if ( key !== 'phone_number' && key !== 'student_roll_number' && key !== 'studentName' && key !== 'userAssessmentResponseID' && key !== 'user_email' && key !== 'product_code' && key !== 'user_name' && key !== 'clientId' ) {
    //                     localStorage.removeItem( key );
    //                 }
    //             } );
    //             setCurrentComponent( currentComponent + 1 );
    //         } else {
    //             localStorage.clear();
    //         }
    //     }
    // }, [createSchoolResponse, createStudentResponse] )

    const handleCancel = () => {
        setCurrentDynamicStep( currentDynamicStep - 1 );
        setIsModalOpen( false );
    };

    const isWide = useMedia( '(max-width: 1050px)' );

    const userEmailLocalStorage = localStorage.getItem( 'user_email' ) || userEmail || "";
    const userNameLocalStorage = localStorage.getItem( 'studentName' ) || userName || "";

    const showInstructionsModal = () => {
        setInstructionModalTest( true )
    };

    const closeInstructionsModal = () => {
        setInstructionModalTest( false );
    }

    const handleProhibitAction = () => {
        updateResponse( { id: userAssessmentResponseID, is_completed: 3, clientName: client_name } )
        dispatch( nextFlow() );
        dispatch( resetState() );
        if ( test_series_id ) {
            navigate( `/${ client_name }/thank_you/${ currentQueryParams }` )
        } else {
            navigate( `/${ client_name }/${ test_type }/flow/` )
        }
    }

    useEffect( () => {
        if ( studentError && studentError?.response?.status ) {
            setUserIdError( true )
        }
    }, [studentError] )

    return dynamicQuestions && userAssessmentResponseID && form_id && (
        <div className="flex justify-center items-center">
            <>
                <Helmet>
                    <link rel="icon" href={ clientConfig.favicon } />
                </Helmet>
                <Modal
                    title="Notice"
                    open={ isClientActiveModal }
                    centered={ true }
                    closable={ false }
                    maskClosable={ false }
                    footer={ null }
                >
                    <p>Service temporarily blocked. Please contact the owner to restore access. Sorry for the inconvenience</p>
                </Modal>
                <Modal
                    title="Note"
                    open={ userIdError }
                    centered={ true }
                    closable={ false }
                    maskClosable={ false }
                    footer={ null }
                >
                    <p p className="text-base font-normal">{ studentError?.response?.data?.detail }</p>
                </Modal>
                {/*<Modal
          title="Authentication Failed"
          visible={ !isUserRegistered }
          centered
          closable={ false }
          maskClosable={ false }
          footer={ [
            <button className="border border-2 text-white font-bold rounded-lg px-4 py-2" onClick={ handleSignUp } style={ { backgroundColor: clientConfig?.primary_color } }>
              Sign Up
            </button>
          ] }
        >
          <p className="text-sm font-normal">You are not registered. Please sign up by clicking on the button below.</p>
        </Modal>*/}
                <AuthModal isUserRegistered={ isUserRegistered } />
                <Modal
                    title="Note"
                    open={ isTestAlreadyGiven }
                    centered
                    closable={ false }
                    maskClosable={ false }
                    footer={ [
                        <button className="border border-2 text-white font-bold rounded-lg px-4 py-2" onClick={ handleProhibitAction } style={ { backgroundColor: clientConfig?.primary_color } }>
                            Let's Move On
                        </button>
                    ] }
                >
                    <p className="text-sm font-normal">Oops! You've already given the test.</p>
                </Modal>
                <Modal
                    title="Note"
                    open={ refreshTest }
                    centered
                    closable={ false }
                    maskClosable={ false }
                    footer={ [
                        <button className="border border-2 text-white font-bold rounded-lg px-4 py-2" onClick={ handleProhibitAction } style={ { backgroundColor: clientConfig?.primary_color } }>
                            Let's Move On
                        </button>
                    ] }
                >
                    <p className="text-sm font-normal">Oops! This action is not allowed. Hence, your assessment has been auto submitted.</p>
                </Modal>
                {
                    ( creatingSchool || creatingStudent || updateUserResponseLoading || studentDataLoading ) &&
                    <div className="overlay">
                        <Spin size="large" />
                    </div>
                }
                { ( isLoading || formConfigLoading ) ?
                    <div className="flex items-center justify-center h-screen">
                        <Lottie animationData={ animationData } loop autoplay style={ { width: '250px', height: '250px' } } />
                    </div>
                    :
                    <>
                        <Modal
                            title="Are you sure you want to submit?"
                            open={ isModalOpen }
                            onOk={ handleOk }
                            onCancel={ handleCancel }
                            cancelText="Go Back"
                            okText="Submit"
                            centered={ true }
                            okButtonProps={ { style: { backgroundColor: '#3b82f6' } } }
                        >
                            <p>(Once submitted, answers cannot be altered)</p>
                        </Modal>
                        <InstructionsModalTest instructions={ instructions } isOpen={ instructionModalTest } onOk={ closeInstructionsModal } />
                        <div className="container-fluid">
                            <div className="row row-height">
                                {
                                    isLeftSidebarVisible && clientConfig && formConfig && !isQuestionNavigation ?
                                        <LeftSideBar
                                            data={ data }
                                            formConfig={ form_config_data }
                                            currentComponentNext={ () => setCurrentComponent( currentComponent + 1 ) }
                                            currentDynamicStepBack={ () => {
                                                if ( currentDynamicStep !== 0 )
                                                    setCurrentDynamicStep( currentDynamicStep - 1 )
                                            } }
                                            currentDynamicStep={ currentDynamicStep }
                                            timeCompleted={ () => setCurrentDynamicStep( currentDynamicStep + 1 ) }
                                            testTimeInSeconds={ testTimeInSeconds }
                                        />
                                        :
                                        ""
                                }
                                {
                                    isLeftSidebarVisible && clientConfig && formConfig && isQuestionNavigation && isWide ?
                                        <LeftSideBar
                                            data={ data }
                                            formConfig={ form_config_data }
                                            currentComponentNext={ () => setCurrentComponent( currentComponent + 1 ) }
                                            currentDynamicStepBack={ () => {
                                                if ( currentDynamicStep !== 0 )
                                                    setCurrentDynamicStep( currentDynamicStep - 1 )
                                            } }
                                            currentDynamicStep={ currentDynamicStep }
                                            timeCompleted={ () => setCurrentDynamicStep( currentDynamicStep + 1 ) }
                                            testTimeInSeconds={ testTimeInSeconds }
                                        /> : ""
                                }
                                { isConsentForm && isClientActive && isUserRegistered && !isTestAlreadyGiven && !refreshTest && !userIdError && (
                                    <TestTermsAndConditions onSubmit={ handleProceedToQuiz } link={ terms_and_conditions } />
                                ) }
                                {/*<div className={ `${ isLeftSidebarVisible ? ( isQuestionNavigation ? 'col-xl-9 col-lg-9 h-full overflow-hidden' : 'col-xl-8 col-lg-8 content-right' ) (isPagination ? "overflow-y-auto" : ""): 'flex justify-center p-10' }` } id="start">*/ }
                                <div ref={ containerRef } className={ `${ isLeftSidebarVisible ? ( isQuestionNavigation ? 'col-xl-9 col-lg-9 h-full' : 'col-xl-8 col-lg-8 content-right' ) : 'flex justify-center p-10' } ${ isLeftSidebarVisible && isPagination ? 'overflow-y-auto' : '' }` } id="start" >
                                    <>
                                        <div className={ `${ showProgressBar ? "justify-start " : "justify-center " }${ isQuestionNavigation ? "w-full h-full flex flex-col" : "flex flex-col w-full xl:w-3/4 lg:w-3/4 sm:w-full sm:gap-10 lg:gap-0 sm:mt-[1rem]" }` }>
                                            <div className={ isQuestionNavigation ? 'flex flex-col p-2' : 'p-4 pt-0 sm:p-4 h-full' } style={ { height: '-webkit-fill-available' } }>

                                                {
                                                    isQuestionNavigation &&
                                                    <>
                                                        <div className="w-full px-4 pt-2 pb-4 flex lg:flex-row lg:items-center lg:justify-between sm:flex-col sm:items-start sm:gap-[1rem] font-poppins">
                                                            <div className="w-fit py-2 px-2 rounded-md" style={ { backgroundColor: clientConfig.primary_color } }>
                                                                <p className="text-white lg:text-lg sm:text-sm font-semibold">{ form_name }</p>
                                                            </div>
                                                            <div className="flex items-center cursor-pointer w-fit py-2 px-2 rounded-md border-2" onClick={ showInstructionsModal }>
                                                                <InfoCircleFilled style={ { marginRight: '8px', fontSize: '20px', color: clientConfig.primary_color } } />
                                                                <p className="font-semibold lg:text-lg sm:text-sm">Instructions</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    !isQuestionNavigation && isClientActive &&
                                                    <>
                                                        <ProgressBar
                                                            completed={ completedProgress }
                                                            maxCompleted={ 100 }
                                                            bgColor={ clientConfig.primary_color }
                                                            isLabelVisible={ false }
                                                            height={ 15 }
                                                            style={ {
                                                                display: 'content',
                                                                alignItems: 'initial',
                                                            } }
                                                        />
                                                        <p className="text-lg font-light mt-2">
                                                            <span style={ { color: clientConfig.primary_color } }>{ completedProgress }%</span> out of <span style={ { color: clientConfig.primary_color } }>100%</span> completed.
                                                        </p>
                                                    </>
                                                }

                                                <div className={ `h-full overflow-hidden ${ isQuestionNavigation ? 'mt-0 px-4 pt-1 flex-1 h-full' : 'mt-8' }` }>
                                                    {
                                                        renderQuestions( currentDynamicStep )
                                                    }
                                                    {
                                                        isWide ?
                                                            <button
                                                                className="fixed bottom-4 right-4 text-white p-2 rounded-full"
                                                                onClick={ () => {
                                                                    window.scrollTo( {
                                                                        top: document.documentElement.scrollHeight,
                                                                        behavior: 'smooth',
                                                                    } );
                                                                } }
                                                                style={ { backgroundColor: clientConfig.secondary_color } }
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-6 w-6"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth={ 2 }
                                                                        d="M19 14l-7 7m0 0l-7-7m7 7V3"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            :
                                                            <></>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                                {
                                    isLeftSidebarVisible && clientConfig && formConfig && isQuestionNavigation && !isWide ?
                                        <LeftSideBar
                                            data={ data }
                                            formConfig={ form_config_data }
                                            currentComponentNext={ () => setCurrentComponent( currentComponent + 1 ) }
                                            currentDynamicStepBack={ () => {
                                                if ( currentDynamicStep !== 0 )
                                                    setCurrentDynamicStep( currentDynamicStep - 1 )
                                            } }
                                            currentDynamicStep={ currentDynamicStep }
                                            timeCompleted={ () => setCurrentDynamicStep( currentDynamicStep + 1 ) }
                                            testTimeInSeconds={ testTimeInSeconds }
                                        /> : ""
                                }
                            </div>
                        </div>
                    </>
                }
            </>
        </div>
    );
};

export default MultiStepForm;
