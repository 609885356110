import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import * as Sentry from "@sentry/react";
import { SENTRY_DSN } from "./utils/constants/constants";

// Sentry.init( {
//     dsn: SENTRY_DSN,
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration(),
//     ],
//     environment: 'strideahead',
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: [
//         "localhost",
//         /^https:\/\/survey\.strideahead\.io/,
//         /^https:\/\/forms-backend-production\.strideahead\.io/
//     ],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// } );

const root = ReactDOM.createRoot( document.getElementById( "root" ) );
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
