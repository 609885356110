import { useState } from "react";
import axios from "axios";
import {
    BASE_URL,
    UPDATE_USER_PROFILE_INFO,
} from "../utils/constants/constants";
import * as Sentry from '@sentry/react';

const useUpdateUserInfo = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const UpdateUserInfo = async ( {
        studentRollNumber,
        userName,
        userPhoneNumber,
        userEmail,
    } ) => {
        setIsLoading( true );

        const requestBody = {
            first_name: userName,
            phone_number: userPhoneNumber,
            email: userEmail,
        };

        try {
            const res = await axios.put(
                `${ BASE_URL }/${ UPDATE_USER_PROFILE_INFO }?user_id=${ studentRollNumber }`,
                requestBody
            );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
            Sentry.captureException( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, UpdateUserInfo };
};

export default useUpdateUserInfo;
