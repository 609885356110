import { useState } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';

const useVerifyOTP = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const verifyOTP = async (phoneNumber, otp) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post('https://auth.strideahead.in/verify-otp', {
        phone_number: phoneNumber,
        otp: otp,
      });

      setData(response.data);
      setIsLoading(false);
    } catch (err) {
      setError(err);
        Sentry.captureException( err );
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, verifyOTP };
};

export default useVerifyOTP;
